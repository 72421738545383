const WaitlistSuccess = () => {

   return (
      <div style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center" }}>
         <p> Thanks for signing up!</p>
         <p>You'll be hearing from us soon!</p>
      </div>
   )
}

export default WaitlistSuccess;