import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import './index.css';
import "antd/dist/reset.css";
import Header from "./components/Header";
import Footer from "./components/Footer"
// import Home from './routes/Home';
// import Checkout from './routes/Checkout';
// import SignUpPage from './routes/SignUpPage';
// import SignUpSuccess from './routes/SignUpSuccess';
import PrivacyPolicy from './routes/PrivacyPolicy';
import WaitlistSuccess from './routes/WaitlistSuccess';
import WaitlistPage from './routes/WaitlistPage';
import { Styles } from './styles/styles' // global styles

// const AppLayout = () => (
//    <>
//       <Header />
//       <Outlet />
//       <Footer />
//    </>
// )

const router = createBrowserRouter([
   // {
   //    path: "/",
   //    element: <AppLayout />,
   //    children: [
   //       {
   //          path: "/",
   //          element: < Home />
   //       },
   //       {
   //          path: "checkout",
   //          element: <Checkout />
   //       },
   //       {
   //          path: "signup/:userId",
   //          element: <SignUpPage />
   //       },
   //       {
   //          path: "signup/success",
   //          element: <SignUpSuccess />
   //       }
   //    ]
   // },
   {
      path: "/", 
      element: <WaitlistPage />
   },
   {
      path: '/waitlist/success',
      element: <WaitlistSuccess /> 
   },
   {
      path: '/privacypolicy',
      element: <PrivacyPolicy />
   }
]);

function App() {
  return (
    <div className="App">
      <Styles />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
