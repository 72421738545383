const ROOT_API_URL = "/api";

const updateUsersSubscriptionData = async (userId, sessionId) => {
   console.log("asking server to update subscription data for user")
   const response = await fetch(`${ROOT_API_URL}/user/${userId}/updateSubscriptionData`, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({sessionId})
   })
   console.log(response)
   return response;
}

const addEmailToWaitlist = async (emailAddress) => {
   console.log(emailAddress)
   const response = await fetch(`${ROOT_API_URL}/waitlist/add`, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({emailAddress})
   })
   console.log(response);
   return response;
}

export { ROOT_API_URL, updateUsersSubscriptionData, addEmailToWaitlist }