import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from "../common/Input";
import { Button } from "../common/Button"
import { addEmailToWaitlist } from '../api/server-api';

const WaitlistPage = () => {
   const navigate = useNavigate()
   const [ email, setEmail ] = useState("")
   const onChange = (e) => {
      setEmail(e.target.value)
   }
   const onClick = (e) => {
      e.preventDefault();  
      addEmailToWaitlist(email);
      navigate("/waitlist/success");
   }

   return (
      <div style={{ height: "100vh", width: "100vw", display: "flex", paddingRight: 20, paddingLeft: 20, flexDirection: "column", justifyContent: "center" }} >
         <p>Palate is an iOS app that turns your favorite Substack blogs into custom podcasts.</p>
         <p style={{ fontSize: 32 }}>Sign up for the wait list to be the first to get access!</p>
            <form>
            <Input value={email} placeholder="john.doe@gmail.com" onChange={onChange}/>
            <Button onClick={onClick}>
               Sign up!
            </Button>
         </form>
      </div>
   )
}
export default WaitlistPage;